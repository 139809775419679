import Api from '@/services/FinanceApi';
import FinanceApi from "@/services/FinanceApi";

const virtualPos = async (formData) => {
    return Api.get('/epayment/virtualpos');
}
const login = async (formData) => {
    return Api.post('/epayment/login', {'identity_number': formData.identityNumber});
}
const loginWithReferenceId = async (referenceId) => {
    return Api.post('/epayment/create/reference', {'reference_id': referenceId});
}
const sendSms = async (formData) => {
    return Api.post('/epayment/sendsms', formData);
}
const verifySms = async (formData) => {
    return Api.put('/epayment/verify/sms', formData);
}

const verifyPersonalInfo = async (formData) => {
    return Api.post('/epayment/verify/personal/info', formData);
}

const debts = async (token) => {
    return Api.get('/epayment/debts', {
        headers: {
            'epayment-token': token
        }
    });
}
const debt = async (token, uuid) => {
    return Api.get('/epayment/debts/' + uuid, {
        headers: {
            'epayment-token': token
        }
    });
}

const pay = async (token, uuid, formData) => {
    return Api.post('/epayment/pay/' + uuid, formData, {
        headers: {
            'epayment-token': token
        }
    });
}

const paymentStatus = async (token, uuid) => {
    return Api.get('/epayment/order/' + uuid, {
        headers: {
            'epayment-token': token
        }
    });
}
const virtualPosBin = async (token, number) => {
    return Api.get('/virtual/pos/bin/' + number, {
        headers: {
            'epayment-token': token
        }
    });
}

const report = async (config) => {
    return FinanceApi.get('/epayment/report', config);
}

const downloadReceipt = async (token, id) => {
    return FinanceApi.get('/epayment/receipt/' + id, {
        responseType: 'arraybuffer',
        headers: {
            'epayment-token': token
        }
    });
}

export default {
    virtualPos,
    login,
    sendSms,
    verifySms,
    verifyPersonalInfo,
    debts,
    debt,
    pay,
    paymentStatus,
    virtualPosBin,
    report,
    loginWithReferenceId,
    downloadReceipt
}
